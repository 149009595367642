import {
  Box,
  Grid, makeStyles, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import { moneyFormatter } from '../utils/formatterUtil';
import backgroundBanner from '../assets/imgs/banner-home.png';

const useStyles = makeStyles(() => ({
  imageWrapper: {
    height: '100%',
    width: '100%',
    borderRadius: '12px',
    backgroundImage: `url(${backgroundBanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    cursor: 'pointer',
    aspectRatio: '181/39',
  },
  textWrapper: {
    textAlign: 'left',
  },
  text: {
    fontFamily: 'Poppins',
    fontWeight: '700',
    lineHeight: '1.5',
  },
}));

const CardOfferBanner = ({
  onClick, textOffer, maxAmountOffer, useSmallBackgroundImage,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  const styleImageWrapper = {
    maxWidth: useSmallBackgroundImage ? '327px' : '100%',
  };

  const styleIconWrapper = {
    marginTop: useSmallBackgroundImage || !isMobile ? '2%' : '5%',
    maxHeight: useSmallBackgroundImage ? '58px' : isMobile ? '90px' : '100px',
  };

  const styleTextWrapper = {
    paddingTop: useSmallBackgroundImage ? '5%' : isMobile ? '5%' : '20px',
  };

  const styleText = {
    fontSize: '0.875rem', /* useSmallBackgroundImage ? '0.7rem' : isMobile ? '0.7rem' : '1.2rem', */
  };

  return (
    <>
      <Grid
        container
        onClick={onClick}
        className={classes.imageWrapper}
        style={styleImageWrapper}
      >
        <Grid item xs={5} style={styleIconWrapper} />
        <Grid item xs={7}>
          <Box className={classes.textWrapper} style={styleTextWrapper}>
            <Typography className={classes.text} style={{ ...styleText, color: '#E72064' }}>
              {textOffer[0]}
            </Typography>
            {isMobile ? (
              <>
                <Typography className={classes.text} style={styleText}>
                  {textOffer[1]}
                </Typography>
                <Typography className={classes.text} style={styleText}>
                  {textOffer[2]}
                  {' '}
                  <span style={{ color: '#E72064' }}>
                    {moneyFormatter(maxAmountOffer)}
                  </span>
                </Typography>
              </>
            ) : (
              <>
                <Typography className={classes.text} style={styleText}>
                  {textOffer[1]}
                  {' '}
                  {textOffer[2]}
                  {' '}
                  <span style={{ color: '#E72064' }}>
                    {moneyFormatter(maxAmountOffer)}
                  </span>
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

CardOfferBanner.propTypes = {
  onClick: PropTypes.func.isRequired,
  textOffer: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxAmountOffer: PropTypes.number.isRequired,
  useSmallBackgroundImage: PropTypes.bool,
};

CardOfferBanner.defaultProps = {
  useSmallBackgroundImage: false,
};

export default CardOfferBanner;
