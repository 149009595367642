import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box, Button, Card, Grid, IconButton, makeStyles, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import i18n from '../common/i18n';
import { moneyFormatter } from '../utils/formatterUtil';
import { getLocalStorageVisibleAmount, saveLocalStorageVisibleAmount } from '../api/userStorage';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px',
    textAlign: 'left',
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
  },
  amount: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginTop: '8px',
    fontSize: '1.75rem',
    fontFamily: 'Poppins',
    display: 'flex',
    alignItems: 'center',
  },

  pendingBackground: {
    backgroundColor: '#F5F5F5',
  },

  pendingColor: {
    color: '#B5B5B5',
  },
}));

const CardRevolvingLine = () => {
  const { hasRevolvingLine, getRevolvingLine, hasDebt } = useCustomerAccountStatus();
  const classes = useStyles();
  const history = useHistory();
  const [visibility, setVisibility] = useState(getLocalStorageVisibleAmount());

  const toggle = () => {
    setVisibility(!visibility);
    saveLocalStorageVisibleAmount(!visibility);
  };

  const goToRevolvingLineDetail = () => history.push(getPathRouteFromKey(i18n.revolvingLineDetail));

  const pendingToActivate = () => getRevolvingLine().campaignOffer && !hasDebt();

  const getRevolvingLineOfferOrActivatedAmount = () =>
    (pendingToActivate() ? getRevolvingLine().campaignOffer.maxAmountOffer : getRevolvingLine().remainingAmount);

  return (
    <>
      {hasRevolvingLine() && (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} style={{ paddingLeft: '24px', paddingRight: '24px' }}>
            <Card className={clsx(classes.container, { [classes.pendingBackground]: pendingToActivate() })}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography>{i18n.RevolvingLine.Header.title}</Typography>
                <Button
                  variant="text"
                  color="primary"
                  style={{ fontSize: '0.875rem', textDecorationLine: 'underline' }}
                  onClick={goToRevolvingLineDetail}
                >
                  {i18n.RevolvingLine.Header.movements}
                </Button>
              </Box>
              <Typography className={clsx(classes.amount, { [classes.pendingColor]: pendingToActivate() })}>
                {visibility ? moneyFormatter(getRevolvingLineOfferOrActivatedAmount()) : '$****,**'}
                <IconButton style={{ padding: '0px', paddingLeft: '8px' }} onClick={toggle}>
                  {visibility
                    ? <VisibilityIcon fontSize="medium" style={{ color: '#424242' }} />
                    : <VisibilityOffIcon fontSize="medium" style={{ color: '#424242' }} />}
                </IconButton>
              </Typography>
              {pendingToActivate() && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '8px' }}
                  onClick={() => history.push(getPathRouteFromKey(i18n.revolvingLineOffer))}
                >
                  {i18n.CardRevolvingLine.cta}
                </Button>
              )}
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CardRevolvingLine;
